<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="secondary" flat>
        <v-toolbar-title>Bin Location Inventory</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="accent" small @click="goBack()">
          <v-icon left dark>mdi-arrow-left</v-icon>Back
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-data-table
              :loading="loading"
              loading-text="Loading... Please wait"
              :headers="headers"
              :items="BinInventory"
              :search="search"
            >
              <template v-slot:item.created_at="{ item }">{{
                item.created_at | moment("LLL")
              }}</template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    search: "",
    loading: false,
    headers: [
      { text: "#", value: "id" },
      { text: "BinCode", value: "bin_location.BinCode" },
      { text: "ItemCode", value: "ItemCode" },
      { text: "ItemName", value: "item.ItemName" },
      { text: "OnHandQty", value: "OnHandQty" },
      { text: "Created On", value: "created_at" },
      { text: "Action", value: "action" },
    ],
    BinInventory: [],
  }),
  methods: {
    getBinLocationInventory() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/bin-location/${this.$route.params.id}/inventory`)
        .then((res) => {
          if (res.ResultCode == 1200) {
            console.log(res.ResponseData);
            self.BinInventory = res.ResponseData;
            self.loading = false;
          }
          if (res.ResultCode == 1043) {
            this.$refs.snackbar.show(res.ResultDesc, "red");
            self.loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.$refs.snackbar.show(err, "red");
          self.loading = false;
        });
    },
    goBack() {
      this.$router.go(-1);
    },
  },

  created() {
    this.getBinLocationInventory();
  },
};
</script>